<template>
  <div class="hd-assessment-vascular-access-op-pta">
    <VContainer fluid>
      <VForm>
        <VContainer>
          <VRow>
            <VCol>
              <VCard>
                <div class="subtitle-2 text-center">血管通路手術紀錄</div>
                <VDivider />
                <VDataTable height="calc(50vh - 190px)" :headers="headers1" :items="items1">
                  <template #[`item.images`]="{ item }">
                    <VHover>
                      <template #default="{ hover }">
                        <VImg
                          style="border-radius: 4px"
                          max-width="240"
                          max-height="40"
                          :src="`https://picsum.photos/seed/${item.vascularPosition}/240`"
                        >
                          <VFadeTransition>
                            <VOverlay v-if="hover" absolute color="black">
                              <VBtn
                                text
                                style="height: 40px; min-width: 240px"
                                @click="image = true"
                              >
                                查看原圖
                              </VBtn>
                            </VOverlay>
                          </VFadeTransition>
                        </VImg>
                      </template>
                    </VHover>
                  </template>
                  <template #[`item.operations`]>
                    <VBtn icon><VIcon>mdi-pencil</VIcon></VBtn>
                  </template>
                </VDataTable>
                <VBtn icon x-small absolute style="top: 9px; right: 9px" @click="dialog1 = true">
                  <VIcon>mdi-plus</VIcon>
                </VBtn>
              </VCard>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VCard>
                <div class="subtitle-2 text-center">血管通路照護紀錄</div>
                <VDivider />
                <VDataTable height="calc(50vh - 190px)" :headers="headers2" :items="items2">
                  <template #[`item.operations`]>
                    <VBtn icon><VIcon>mdi-pencil</VIcon></VBtn>
                  </template>
                </VDataTable>
                <VBtn icon x-small absolute style="top: 9px; right: 9px" @click="dialog2 = true">
                  <VIcon>mdi-plus</VIcon>
                </VBtn>
              </VCard>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VContainer>
    <VDialog v-model="dialog1" persistent max-width="320px">
      <VCard>
        <VToolbar color="primary" dark dense>
          <VIcon left>mdi-plus</VIcon>
          <VToolbarTitle>新增血管通路手術紀錄</VToolbarTitle>
          <VSpacer />
          <VBtn icon @click="dialog1 = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VContainer>
          <VForm>
            <VContainer>
              <VRow>
                <VCol>
                  <VTextField dense hide-details label="日期" value="2021年07月01日" />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VCombobox
                    dense
                    multiple
                    small-chips
                    hide-details
                    label="血管位置"
                    menu-props="offsetY"
                    :items="['左', '右', '上臂', '前臂', '大腿', 'FV', 'SCV', 'JV']"
                    :value="['左', '上臂']"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VSelect
                    dense
                    multiple
                    small-chips
                    hide-details
                    label="血管通路"
                    menu-props="offsetY"
                    :items="['A-VF', 'A-VG', '內A', '外A', 'perm catheter']"
                    :value="['A-VG']"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VTextField dense hide-details label="執行醫師" value="◯◯◯" />
                </VCol>
                <VCol>
                  <VTextField dense hide-details label="紀錄者" value="◯◯◯" />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VFileInput dense hide-details label="上傳圖檔" />
                </VCol>
              </VRow>
            </VContainer>
          </VForm>
        </VContainer>
        <VDivider />
        <VCardActions>
          <VSpacer />
          <VBtn depressed color="primary" @click="dialog1 = false">新增</VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <VDialog v-model="dialog2" persistent max-width="320px">
      <VCard>
        <VToolbar color="primary" dark dense>
          <VIcon left>mdi-plus</VIcon>
          <VToolbarTitle>新增血管通路照護紀錄</VToolbarTitle>
          <VSpacer />
          <VBtn icon @click="dialog2 = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VContainer>
          <VForm>
            <VContainer>
              <VRow>
                <VCol>
                  <VTextField dense hide-details label="日期" value="2021年07月01日" />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VCombobox
                    dense
                    multiple
                    small-chips
                    hide-details
                    label="血管位置"
                    menu-props="offsetY"
                    :items="['左', '右', '上臂', '前臂', '大腿', 'FV', 'SCV', 'JV']"
                    :value="['左', '上臂']"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VSelect
                    dense
                    multiple
                    small-chips
                    hide-details
                    label="血管通路"
                    menu-props="offsetY"
                    :items="['A-VF', 'A-VG', '內A', '外A', 'perm catheter']"
                    :value="['A-VG']"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VSelect
                    dense
                    hide-details
                    label="症狀"
                    menu-props="offsetY"
                    :items="[
                      '血流不足',
                      '穿刺不易',
                      'failure',
                      '感染',
                      '靜脈壓力高',
                      '腫脹',
                      '疼痛',
                      '常規檢查',
                    ]"
                    value="血流不足"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VTextField dense hide-details label="原因" value="狹窄：100%" />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VCombobox
                    dense
                    multiple
                    small-chips
                    hide-details
                    label="處置"
                    menu-props="offsetY"
                    :items="['PTA', 'stent']"
                    :value="['PTA', 'stent']"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VTextField dense hide-details label="執行醫師" value="◯◯◯" />
                </VCol>
                <VCol>
                  <VTextField dense hide-details label="紀錄者" value="◯◯◯" />
                </VCol>
              </VRow>
            </VContainer>
          </VForm>
        </VContainer>
        <VDivider />
        <VCardActions>
          <VSpacer />
          <VBtn depressed color="primary" @click="dialog2 = false">新增</VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <VDialog v-model="image" max-width="calc(80vh / 4 * 3)" max-height="80vh">
      <VCard>
        <VImg src="https://picsum.photos/1080/1440" lazy-src="https://picsum.photos/108/144" />
        <VBtn absolute top right icon dark @click="image = false">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'HDAssessmentVascularAccessOPpta',
  data: () => ({
    headers1: [
      { text: '日期', value: 'date' },
      { text: '血管位置', value: 'vascularPosition' },
      { text: '血管通路', value: 'vascularAccess' },
      { text: '執行醫師', value: 'executivePhysician' },
      { text: '圖檔', value: 'images' },
      { text: '紀錄者', value: 'recorder' },
      { text: '操作', value: 'operations' },
    ],
    items1: [
      {
        date: '2018/09/10',
        vascularPosition: '左上臂',
        vascularAccess: 'AVF',
        executivePhysician: '◯◯◯',
        recorder: '◯◯◯',
      },
      {
        date: '2018/09/10',
        vascularPosition: '右上臂',
        vascularAccess: 'AVF',
        executivePhysician: '◯◯◯',
        recorder: '◯◯◯',
      },
    ],
    headers2: [
      { text: '日期', value: 'date' },
      { text: '血管位置', value: 'vascularPosition' },
      { text: '血管通路', value: 'vascularAccess' },
      { text: '症狀', value: 'symptom' },
      { text: '原因及處置', value: 'reasonAndTreatment' },
      { text: '執行醫師', value: 'executivePhysician' },
      { text: '紀錄者', value: 'recorder' },
      { text: '操作', value: 'operations' },
    ],
    items2: [
      {
        date: '2018/09/10',
        vascularPosition: '右上臂',
        vascularAccess: 'AVF',
        symptom: '組套',
        reasonAndTreatment: '原因及處置',
        executivePhysician: '◯◯◯',
        recorder: '◯◯◯',
      },
      {
        date: '2018/09/10',
        vascularPosition: '左上臂',
        vascularAccess: 'AVF',
        symptom: '組套',
        reasonAndTreatment: '原因及處置',
        executivePhysician: '◯◯◯',
        recorder: '◯◯◯',
      },
    ],
    dialog1: false,
    dialog2: false,
    image: false,
  }),
  methods: {
    showContextMenu(event) {
      event.preventDefault();
      this.contextMenuIsShown = false;
      this.cursor.x = event.clientX;
      this.cursor.y = event.clientY;
      this.$nextTick().then(() => {
        this.contextMenuIsShown = true;
      });
    },
  },
});
</script>

<style lang="scss">
.hd-assessment-vascular-access-op-pta {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }

  > .container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0px;
    .v-form {
      .container {
        max-width: 1200px;
        width: 1200px;
        padding: 8px;
        .row {
          margin: -4px;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }

        .col {
          padding: 4px;
        }

        .v-card {
          padding: 8px;
          .v-divider {
            padding: 4px;
          }

          .row {
            margin: -4px;
          }

          .col {
            padding-top: 12px;
            .v-input {
              padding-top: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
